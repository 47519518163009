import React from 'react';
import ImageGridReview from './ImageGridReview';

function Review() {

    return (
        <>
            <ImageGridReview />
        </>
    );
}

export default Review;
