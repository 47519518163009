import React from 'react';
import ImageGrid from './ImageGrid';

function Home() {

    return (
        <>
            <ImageGrid />
        </>
    );
}

export default Home;
