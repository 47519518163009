import axios from 'axios';

const fetchReviewImagesList = async (page, size) => {
    try {
        const response = await axios.get(`reviewimages?pageNumber=${page}&pageSize=${size}`);
        const imageMetas = response.data;

        const newInitiallyShownImages = new Set();

        const imageDetails = imageMetas.map(imageMeta => {
            const imageUrl = `images/${imageMeta.name}`;
            const imageThumbnailUrl = `images/${imageMeta.name}_thumbnail`;

            if (imageMeta.show) {
                newInitiallyShownImages.add(imageMeta.name);
            }

            return { ...imageMeta, url: imageUrl, thumbnailUrl: imageThumbnailUrl };
        });

        return { imageDetails, newInitiallyShownImages };
    } catch (error) {
        console.error("Error fetching review images:", error);
        throw error; // Re-throw the error for handling in the component
    }
}

const updateReviewImagesList = async (imagesToShow, imagesToHide) => {
    try {
        const data = {
            ImagesToShow: Array.from(imagesToShow),
            ImagesToHide: Array.from(imagesToHide),
        };
        await axios.put('/reviewimages/visibility/bulk', data);
    } catch (error) {
        console.error(error.response ? error.response.data : error.message);
        throw error; // Re-throw the error for handling in the component
    }
}

const updateReviewImage = async (checkImage) => {
    try {
        const data = {
            ImageToShow: checkImage
        };
        await axios.put('/reviewimages/visibility/single', data); // TODO: reuse same function for speed, consider perform for heavier loads 
    } catch (error) {
        console.error(error.response ? error.response.data : error.message);
        throw error; // Re-throw the error for handling in the component
    }
}

export { fetchReviewImagesList, updateReviewImagesList, updateReviewImage };
