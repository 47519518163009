import axios from 'axios';

const fetchImagesList = async (page, size, baseUrl = '') => {
    try {
        const response = await axios.get(`${baseUrl}images?pageNumber=${page}&pageSize=${size}`);
        const imageMetas = response.data;

        const imageDetails = imageMetas.map(imageMeta => {
            const imageUrl = `${baseUrl}images/${imageMeta.name}`;
            const imageThumbnailUrl = `${baseUrl}images/${imageMeta.name}_thumbnail`;
            return { ...imageMeta, url: imageUrl, thumbnailUrl: imageThumbnailUrl };
        });

        return imageDetails;
    } catch (error) {
        console.error("Error fetching images:", error);
        // Axios wraps the error response, so you might want to handle or re-throw it differently
        throw error;
    }
}

export { fetchImagesList };
