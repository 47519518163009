import React, { useState, useEffect } from 'react';
import { fetchImagesList } from '../services/ImagesService';
import { Container, Row, Col, Button, Alert, Spinner } from 'reactstrap';
import './ImageGrid.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

function ImageGrid() {
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const pageSize = 100; // Number of images per page
    const [isLoading, setIsLoading] = useState(false);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const minSwipeDistance = 50;
    const [error, setError] = useState('');
    const [isImageLoading, setIsImageLoading] = useState(false);

    useEffect(() => {

        const loadImages = async () => {
            setIsLoading(true);
            setError(''); // Reset error before loading
            try {
                const imageDetails = await fetchImagesList(pageNumber, pageSize);
                if (pageNumber === 1) {
                    setImages(imageDetails);
                } else {
                    setImages(prev => [...prev, ...imageDetails]);
                }
            } catch (error) {
                setError('Failed to load images. Please try again later.'); // Set an error message
            }
            setIsLoading(false);
        };

        loadImages();
    }, [pageNumber, pageSize]);

    function handleImageClick(image) {
        setSelectedImage(image);
        setIsImageLoading(true); 
        document.body.style.overflow = 'hidden'; // Disable scrolling when modal is open
    }

    function handleImageLoaded() {
        setIsImageLoading(false); // Set image loading to false when image is loaded
    }

    function handleClose() {
        setSelectedImage(null);
        document.body.style.overflow = 'auto'; // Re-enable scrolling when modal is closed
    }

    // Functions for next and previous image
    function handleNextImage(e) {
        e.stopPropagation();
        const currentIndex = images.findIndex(img => img.name === selectedImage.name);
        const nextIndex = (currentIndex + 1) % images.length;
        setSelectedImage(images[nextIndex]);
        setIsImageLoading(true); 
    }

    function handlePreviousImage(e) {
        e.stopPropagation();
        const currentIndex = images.findIndex(img => img.name === selectedImage.name);
        const previousIndex = (currentIndex - 1 + images.length) % images.length;
        setSelectedImage(images[previousIndex]);
        setIsImageLoading(true); 
    }

    function handleTouchStart(e) {
        setTouchEnd(null); // Reset touch end to ensure new swipe
        setTouchStart(e.targetTouches[0].clientX);
    }

    function handleTouchMove(e) {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd() {
        if (!touchStart || !touchEnd) return; // Ensure both values are set
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            handleNextImage(); // Go to next image on left swipe
        } else if (isRightSwipe) {
            handlePreviousImage(); // Go to previous image on right swipe
        }
    }


    return (
        <>
            {error && (
                <Alert color="danger" className="mt-3">
                    {error}
                </Alert>
            )}
            <Alert color="warning" className="mt-3">
                Published photos will be reviewed first. Please check back later if you've submitted a pet meme from the app
            </Alert>
            <div className="image-grid">
                <div className="image-thumbnail-text">
                    <h3>Hall of Fame</h3>
                    <p></p>
                </div>
                {isLoading && (
                    <div className="loading-container">
                        <Spinner style={{ width: '3rem', height: '3rem' }} /> {/* Adjust size as needed */}
                    </div>
                )}
                {images.map(image => (
                    <div className="image-thumbnail"
                        key={image.name}
                        style={{ backgroundImage: `url(${image.thumbnailUrl}?thumbnail=true)` }}
                        onClick={() => handleImageClick(image)}>
                    </div>
                ))}
                {images.length % pageSize === 0 && images.length !== 0 && (
                    <Button className="image-thumbnail" color="primary" onClick={() => setPageNumber(prev => prev + 1)}>
                        {isLoading ? "Loading..." : "Load More"}
                    </Button>
                )}

                {selectedImage && (
                    <div className="image-modal" onClick={handleClose}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}>
                        <img src={selectedImage.url} alt={selectedImage.name} onLoad={handleImageLoaded} />
                        <Button className="modal-arrow left-arrow" onClick={handlePreviousImage}>
                            <FaArrowLeft />
                        </Button>
                        <Button className="modal-arrow right-arrow" onClick={handleNextImage}>
                            <FaArrowRight />
                        </Button>
                        {isImageLoading && <div className="loading-modal">
                            <Spinner style={{ width: '3rem', height: '3rem' }} /> {/* Adjust size as needed */}
                        </div>}
                    </div>
                )}
            </div>
        </>
    );
}

export default ImageGrid;
