import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import AppAdminRoutes from './routes/AppAdminRoutes';
import Layout from './components/Layout';
import './custom.css';

function App() {

    return (
        <Layout>
            <Routes>
                {/* Render public routes */}
                {AppRoutes.map((route, index) => {
                    const { element, ...rest } = route;
                    return <Route key={`public-route-${index}`} {...rest} element={element} />;
                })}

                {/* Render private routes */}
                {AppAdminRoutes.map((route, index) => {
                    const { element, ...rest } = route;
                    return <Route key={`admin-route-${index}`} {...rest} element={element} />;
                })}
            </Routes>
        </Layout>
    );
}

export default App;
