import React, { useState, useEffect } from 'react';
import { fetchReviewImagesList, updateReviewImagesList } from '../services/ReviewImagesService';
import { Container, Row, Col, Button, Alert, Spinner } from 'reactstrap';
import './ImageGridReview.css';
import { FaRegSquare, FaCheckSquare } from 'react-icons/fa';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

function ImageGridReview() {
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const pageSize = 100; // Number of images per page
    const [isLoading, setIsLoading] = useState(false);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const minSwipeDistance = 50;
    const [imagesToShow, setImagesToShow] = useState(new Set());
    const [imagesToHide, setImagesToHide] = useState(new Set());
    const [initiallyShownImages, setInitiallyShownImages] = useState(new Set());
    const [isPublishing, setIsPublishing] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isImageLoading, setIsImageLoading] = useState(false);

    useEffect(() => {
        const loadImages = async () => {
            setIsLoading(true);
            setError('');
            try {
                const { imageDetails, newInitiallyShownImages } = await fetchReviewImagesList(pageNumber, pageSize);
                setInitiallyShownImages(newInitiallyShownImages);
                if (pageNumber === 1) {
                    setImages(imageDetails);
                } else {
                    setImages(prev => [...prev, ...imageDetails]);
                }
            } catch (error) {
                setError('Failed to load images. Please try again later.');
            }
            setIsLoading(false);
        };

        loadImages();

    }, [pageNumber, pageSize]);

    function handleImageClick(image) {
        setSelectedImage(image);
        setIsImageLoading(true);
        document.body.style.overflow = 'hidden'; // Disable scrolling when modal is open
    }

    function handleImageLoaded() {
        setIsImageLoading(false); // Set image loading to false when image is loaded
    }

    function handleClose() {
        setSelectedImage(null);
        document.body.style.overflow = 'auto'; // Re-enable scrolling when modal is closed
    }

    // Functions for next and previous image
    function handleNextImage(e) {
        e.stopPropagation();
        const currentIndex = images.findIndex(img => img.name === selectedImage.name);
        const nextIndex = (currentIndex + 1) % images.length;
        setSelectedImage(images[nextIndex]);
        setIsImageLoading(true);
    }

    function handlePreviousImage(e) {
        e.stopPropagation();
        const currentIndex = images.findIndex(img => img.name === selectedImage.name);
        const previousIndex = (currentIndex - 1 + images.length) % images.length;
        setSelectedImage(images[previousIndex]);
        setIsImageLoading(true);
    }

    function handleTouchStart(e) {
        setTouchEnd(null); // Reset touch end to ensure new swipe
        setTouchStart(e.targetTouches[0].clientX);
    }

    function handleTouchMove(e) {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd() {
        if (!touchStart || !touchEnd) return; // Ensure both values are set
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            handleNextImage(); // Go to next image on left swipe
        } else if (isRightSwipe) {
            handlePreviousImage(); // Go to previous image on right swipe
        }
    }

    function toggleImageSelection(event, image) {
        event.stopPropagation();

        setImagesToShow(prevImagesToShow => {
            const newImagesToShow = new Set(prevImagesToShow);
            const isInitiallyShown = initiallyShownImages.has(image.name);
            const isCurrentlyShown = newImagesToShow.has(image.name) || isInitiallyShown;

            if (isCurrentlyShown) {
                // If the image is currently shown, remove it from imagesToShow if it was there
                newImagesToShow.delete(image.name);

                setImagesToHide(prevImagesToHide => {
                    const newImagesToHide = new Set(prevImagesToHide);
                    // Add to imagesToHide
                    newImagesToHide.add(image.name);
                    return newImagesToHide;
                });

                // If it was initially shown, we need to update initiallyShownImages as well
                if (isInitiallyShown) {
                    setInitiallyShownImages(prevInitiallyShown => {
                        const newInitiallyShown = new Set(prevInitiallyShown);
                        newInitiallyShown.delete(image.name);
                        return newInitiallyShown;
                    });
                }
            } else {
                // If the image is not currently shown, add it to imagesToShow
                newImagesToShow.add(image.name);

                // Ensure it's not in imagesToHide
                setImagesToHide(prevImagesToHide => {
                    const newImagesToHide = new Set(prevImagesToHide);
                    newImagesToHide.delete(image.name);
                    return newImagesToHide;
                });
            }

            return newImagesToShow;
        });
    }

    async function publishSelectedImages() {
        setIsPublishing(true);
        setError('');
        setSuccessMessage('');
        try {
            await updateReviewImagesList(imagesToShow, imagesToHide)

            // Update success message
            setSuccessMessage(`${imagesToShow.size} images added. ${imagesToHide.size} images removed.`);

            // Update initiallyShownImages
            setInitiallyShownImages(prevInitiallyShown => {
                const newInitiallyShown = new Set(prevInitiallyShown);

                // Add images from imagesToShow to initiallyShownImages
                imagesToShow.forEach(image => newInitiallyShown.add(image));

                // Remove images from imagesToHide from initiallyShownImages
                imagesToHide.forEach(image => newInitiallyShown.delete(image));

                return newInitiallyShown;
            });

            // Clear imagesToShow and imagesToHide
            setImagesToShow(new Set());
            setImagesToHide(new Set());
        } catch (error) {
            console.error("Error updating image visibility:", error);
            setError('Failed to publish images. Please try again later.');
        }

        setIsPublishing(false);
    }

    return (
        <>
            {error && (
                <Alert color="danger" className="mt-3">
                    {error}
                </Alert>
            )}
            {successMessage && (
                <Alert color="success" className="mt-3">
                    {successMessage}
                </Alert>
            )}
            <div className="publish-button-container">
                <Button color="primary" onClick={publishSelectedImages} disabled={isPublishing}>
                    {isPublishing ? "Processing..." : "Publish Selected"}
                </Button>
            </div>
            <div className="image-grid">
                <div className="image-thumbnail-text">
                    <h3>Hall of Fame Review</h3>
                </div>
                {images.map(image => (
                    <div className={`image-thumbnail ${imagesToShow.has(image.name) || initiallyShownImages.has(image.name) ? 'selected' : ''}`}
                        key={image.name}
                        style={{ backgroundImage: `url(${image.thumbnailUrl}?thumbnail=true)` }}
                        onClick={() => handleImageClick(image)}>
                        <div onClick={(event) => toggleImageSelection(event, image)} className="checkbox-icon">
                            {(imagesToShow.has(image.name) || initiallyShownImages.has(image.name)) ? <FaCheckSquare className="tick-icon" /> : <FaRegSquare className="tick-icon" />}
                        </div>
                    </div>
                ))}
                {images.length % pageSize === 0 && images.length !== 0 && (
                    <Button className="image-thumbnail" color="primary" onClick={() => setPageNumber(prev => prev + 1)}>
                        {isLoading ? "Loading..." : "Load More"}
                    </Button>
                )}

                {selectedImage && (
                    <div className="image-modal" onClick={handleClose}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}>
                        <img src={selectedImage.url} alt={selectedImage.name} onLoad={handleImageLoaded} />
                        <Button className="modal-arrow left-arrow" onClick={handlePreviousImage}>
                            <FaArrowLeft />
                        </Button>
                        <Button className="modal-arrow right-arrow" onClick={handleNextImage}>
                            <FaArrowRight />
                        </Button>
                        {isImageLoading && <div className="loading-modal">
                            <Spinner style={{ width: '3rem', height: '3rem' }} /> {/* Adjust size as needed */}
                        </div>}
                    </div>
                )}
            </div>
        </>
    );
}

export default ImageGridReview;
