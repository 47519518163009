import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form, FormGroup, Label, Input, Container, Alert } from 'reactstrap';
import './Login.css'; // Import the custom CSS

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginStatus, setLoginStatus] = useState(null); // null, 'success', or 'error'

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoginStatus(null); // Reset login status on new submission

        try {
            const response = await axios.post('/auth/login', { username, password });

            // Handle response here
            console.log(response);
            setLoginStatus('success'); // Set login status to success
        } catch (error) {
            console.error('Login error', error);
            setLoginStatus('error'); // Set login status to error
        }
    };

    return (
        <Container className="mt-5 custom-container">
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="username">Username</Label>
                    <Input
                        type="text"
                        name="username"
                        id="username"
                        placeholder="Enter username"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="password">Password</Label>
                    <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </FormGroup>

                <Button color="primary" type="submit">Submit</Button>
            </Form>

            {loginStatus === 'success' && (
                <Alert color="success" className="mt-3">
                    Login successful!
                </Alert>
            )}

            {loginStatus === 'error' && (
                <Alert color="danger" className="mt-3">
                    Login failed. Please check your credentials.
                </Alert>
            )}
        </Container>
    );
}

export default Login;
