import React, { useState, useEffect } from 'react';
import { Button, Spinner, Alert } from 'reactstrap';
import { updateReviewImage } from '../services/ReviewImagesService';
import { useSearchParams } from 'react-router-dom';

const ImageViewer = () => {
    const [searchParams] = useSearchParams();
    const checkImage = searchParams.get('image');
    const [isImageLoading, setIsImageLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleVisibilityChange = async () => {
        setError('');
        setSuccessMessage('');
        setIsSubmitting(true);
        try {
            await updateReviewImage(checkImage);
            setSuccessMessage('Success: Image visibility updated.');
        } catch (error) {
            setError(`Error: ${error.message}`);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            {error && <Alert color="danger" className="mt-3">{error}</Alert>}
            {successMessage && <Alert color="success" className="mt-3">{successMessage}</Alert>}
            <div className="image-viewer" style={{ overflow: 'hidden', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', height: 'calc(100vh - 20px)', maxHeight: '100vh' }}>
                {isImageLoading && <Spinner color="primary" />}
                <img
                    src={`images/${checkImage}`}
                    alt="Checked Image"
                    style={{ maxHeight: 'calc(100vh - 60px)', maxWidth: '100%', display: isImageLoading ? 'none' : 'block' }} // Adjusted for header/nav if present
                    onLoad={() => setIsImageLoading(false)}
                />
                <Button color="primary" onClick={handleVisibilityChange} disabled={isSubmitting} style={{ marginTop: '10px', alignSelf: 'center' }}>
                    {isSubmitting ? <Spinner size="sm" /> : 'Change Visibility'}
                </Button>
            </div>
        </>
    );
}

export default ImageViewer;