import Policy from "../components/Policy";
import Home from "../components/Home";
import Login from "../components/Login";
import CheckPublished from "../components/CheckPublished";

const AppRoutes = [
    {
        index: true,
        element: <Home />,
        tag: "published"
    },
    {
        path: '/policy',
        element: <Policy />,
        tag: "policy"
    },
    {
        path: '/login',
        element: <Login />,
        tag: "login"
    },
    {
        path: '/check',
        element: <CheckPublished />,
        tag: "check"
    }
];

export default AppRoutes;
