import React, { useState } from 'react';
import {
    Collapse,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col
} from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import googlePlaySVG from '../assets/google-play.svg';
import appStoreSVG from '../assets/app-store.svg';
import logoSVG from '../assets/appiconfg.svg';

function NavMenu() {
    const [collapsed, setCollapsed] = useState(true);

    function toggleNavbar() {
        setCollapsed(!collapsed);
    }

    return (
        <header>
            <Navbar color="dark" dark expand="sm" fixed="top" className="navbar-custom border-bottom box-shadow mb-3">
                <NavbarBrand tag={Link} to="/" className="fs-1">
                    <div className="svg-background">
                        <img src={logoSVG} alt="Logo" className="navbar-logo" />
                    </div>
                    Pet Memes App
                </NavbarBrand>
                <NavbarToggler onClick={toggleNavbar} />
                <Collapse isOpen={!collapsed} navbar>
                    <Nav className="ms-auto" navbar>
                        <NavItem>
                            <NavLink tag={Link} to="/" className="text-white">Hall of Fame</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/policy" className="text-white">Privacy Policy</NavLink>
                        </NavItem>
                        {/* ... additional nav items ... */}
                    </Nav>
                </Collapse>
                <div className="orange-strip"></div> {/* Orange strip */}
            </Navbar>
            <Container>
                <Row>
                    <Col className="d-flex justify-content-center align-items-center">
                        <a href="https://play.google.com/store/apps/details?id=uk.co.yesbananas.petmemes" target="_blank" rel="noopener noreferrer">
                            <img src={googlePlaySVG} alt="Google Play Store" className="store-icon" />
                        </a>
                        <a href="https://apps.apple.com/app/id6470710383" target="_blank" rel="noopener noreferrer">
                            <img src={appStoreSVG} alt="Apple App Store" className="store-icon" />
                        </a>
                    </Col>
                </Row>
            </Container>
        </header>
    );
}

export default NavMenu;
