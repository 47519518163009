import Review from "../components/Review";
import PrivateRoute from './PrivateRoute'; // Import the PrivateRoute component


const AppAdminRoutes = [
    {
        path: '/admin/review',
        element: <PrivateRoute><Review /></PrivateRoute>, // Wrap Review component with PrivateRoute
        tag: "review"
    }
];

export default AppAdminRoutes;
