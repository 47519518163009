import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { checkAuthentication } from '../services/AuthService';

const PrivateRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const verifyAuth = async () => {
            const authStatus = await checkAuthentication();
            setIsAuthenticated(authStatus);
        };

        verifyAuth();
    }, []);

    if (isAuthenticated === null) {
        return <div>Loading...</div>; // or some loading spinner
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
};

function checkCookie(cookieName) {
    const cookies = document.cookie.split('; ');
    const cookie = cookies.find(row => row.startsWith(cookieName + '='));
    return cookie ? true : false;
}

export default PrivateRoute;